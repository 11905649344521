import { Menu, Card, Select, Row, Col, Button, Spin, Popover, Tabs, DatePicker, Checkbox, message, Modal, Input, Tooltip, Dropdown } from "antd";
import React, { useEffect, useState } from "react"; 
import { Scrollbars } from "react-custom-scrollbars";
import { LoadingOutlined, DownloadOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { useParams } from "react-router-dom";
import useDebounce from "../../lib/hooks/useDebounce";
import { Table } from  'react-bootstrap';
import './pivotsample.css';
import AddFilter from "../../assets/images/addFilter.svg";
import Minimise from "../../assets/images/pivotMinimise.svg";
import RunButton from "../../assets/images/runButton.svg";
import MeasureClose from "../../assets/images/msrClose.svg";
import Open from "../../assets/images/open.svg";
import SaveIcon from "../../assets/images/save.svg";
import Charts from "../../assets/images/charts.svg";
import Customise from "../../assets/images/customise.svg";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import MeasureIcon from "../../assets/images/measures.svg";
import DimensionIcon from "../../assets/images/dimension.svg";
import Maximise from "../../assets/images/pivotMaximise.svg";
import "antd/dist/antd.css";
import "../../styles/antd.css";
import AdaptiveFormat from "../../lib/adaptiveFormating";
// import ReactHTMLTableToExcel from '../PivotSample/download';
import { useGlobalContext,useWindowContext } from "../../lib/storage";
import { getPivotData, getViews, upsertViews,getFilterDropdownData } from "../../services/generic";
import moment from 'moment';
import { getTasks, getTaskDetails } from "../../services/genericForCustom";

const { SubMenu } = Menu;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const CwAnalytics = (props) => {
    const { globalStore } = useGlobalContext();
    const { windowStore, setWindowStore } = useWindowContext();
    const windowDefinition = { ...windowStore.windowDefinition };
    const { headerTab } = props;
    const dagUrl = process.env.REACT_APP_genericUrl;
    const [mainData, setMainData] = useState([]);
    const [mainDataLoading, setMainDataLoading] = useState(false);
    const [dimensionsValue, setDimensionsValue] = useState('');
    const [dimensionsName, setDimensionsName] = useState('');
    const [rowValue, setRowValue] = useState([]);
    const [columnValue, setColumnValue] = useState([]);
    const [measuresValue, setMeasuresValue] = useState([]);
    const { windowId } = useParams();
    const [columnForFilters, setColumnForFilters] = useState('');
    const [operator, setOperator] = useState('IN');
    const [valueForFilters, setValueForFilters] = useState([]);
    const [valueDropdown, setValueDropdown] = useState([]);
    const [searchValue,setSearchValue] = useState('')
    const [valueItem, setValueItem] = useState('');
    const [filters, setFilters] = useState([]);
    const [finalFilters, setFinalFilters] = useState([]);
    const [filterLoading, setFilterLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const [data, setData] = useState(() => {
      // Filter headerTab?.fields based on conditions
      return (headerTab?.fields || []).filter(
        (item) =>
          // item.nt_base_reference_id !== "11" &&
          // item.nt_base_reference_id !== "22" &&
          item.column_name !== null &&
          !item.ad_fieldgroup_id
      );
    });
    const [limit, setLimit] = useState('100');
    const [flag, setFlag] = useState(false);
    const [tableColumnData, setTableColumnData] = useState([]);
    const [sumForTable, setSumForTable] = useState([]);
    const [bodyData, setBodyData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(false);

    const [isMeasure, setIsMeasure] = useState(true);
    const [isMinimise, setMinimise] = useState(true);
    const [isDimension, setDimensions] = useState(true);
    const [isVerticalMeasure, setVerticalMeasure] = useState(false);
    const [isCustomise, setCustomise] = useState(false);

    const [selectedBaseRefId, setSelectedBaseRefId] = useState(null);
    const [dataIndexWithoutIdn,setDataIndexWithoutIdn] = useState(null);

    const [checkbox, setCheckBox] = useState(false);
    const [dateValue, setDateValue] = useState('');
    const [currentFromDate, setCurrentFromDate] = useState(null);
    const [currentToDate, setCurrentToDate] = useState(null);
    const [previousFromDate, setPreviousFromDate] = useState('');
    const [previousToDate, setPreviousToDate] = useState('');
    const [compareFlag, setCompareFlag] = useState(true);
    const [currentDateValue, setCurrentDateValue] = useState();
    const [previousDateValue, setPreviousDateValue] = useState();

    const [visibleViewModal, setVisibleViewModal] = useState(false);
    const [viewName, setViewName] = useState('');
    const [openVisible, setOpenVisible] = useState(false);
    const [openData, setOpenData] = useState([]);
    const [viewFlag, setViewFlag] = useState(false);
    const [openFlag, setOpenFlag] = useState('');

    const [chartsVisible, setChartsVisible] = useState(false);

    const [formatValue, setFormatValue] = useState('NumberFormat');

    const [filename, setFilename] = useState('');
    const [fileFlag, setFileFlag] = useState(false);

    const [colorArray, setColorArray] = useState([]);
    const [url, setUrl] = useState('');

    useEffect(async () => {
      let isMounted = true;
      const response = await getViews("7406");
      if (response) {
        if (isMounted) {
          const data = response;
          setOpenData(data);
        };
      };
      return () => {
        isMounted = false;
      };
    }, [viewFlag]);

    useEffect(() => {
      if (currentFromDate !== null && currentToDate !== null) {
        let arr = {};
        arr["field_id"] = dateValue;
        arr["operator"] = "Between";
        arr["values"] = `${currentFromDate};${currentToDate}`;
        setFilters(prevArray => [...prevArray, arr]);
      } else {
        // Remove the date filter if the date range is cleared
        setFilters(prevArray => prevArray.filter(filter => filter.field_id !== dateValue));
      }
    }, [currentFromDate, currentToDate]);
  
    
    useEffect(() => {
      if (valueForFilters?.length !== 0) {
      } else {
        // Remove the filter if valueForFilters is empty
        setFilters(prevArray => prevArray.filter(filter => filter.field_id !== dataIndexWithoutIdn));
      }
    }, [valueForFilters]);
    
    const getValueForFilters = async() => {
      // if (valueDropdown.length > 0 ) {

      // } else if (columnForFilters !== '') {
        setFilterLoading(true);
        const dropDownData = await getFilterDropdownData(windowDefinition.ad_window_id,headerTab.ad_tab_id,dataIndexWithoutIdn, null, null);
        // Axios({
        //   url: `${url}/getFilterParams`,
        //   method: 'POST',
        //   crossDomain: true,
        //   data: {
        //     "table": dimensionsName,
        //     "column": columnForFilters,
        //     "search": "",
        //     "limit": 500,
        //     "database" : "pg"
        //   }
        // }).then((response) => {
        //  console.log(JSON.parse(dropDownData),"------>dropdowndatya")
          const data = JSON.parse(dropDownData).searchData;
          // setValueItem(Object.keys(data[0])[0]);
          setValueDropdown(data);
          setFilterLoading(false);

    };


  
    const onSearch = async(searchText) => {
      const searchTextValue = searchText.target.value;
      setFilterLoading(true);
      const dropDownData = await getFilterDropdownData(
        windowDefinition.ad_window_id,
        headerTab.ad_tab_id,
        dataIndexWithoutIdn,
        searchTextValue,
        null,
        null
      );
      const data = JSON.parse(dropDownData).searchData;
      setValueDropdown(data);
        setFilterLoading(false);


    };

    const onClose = () => {
      setVisible(false);
    };
// console.log(filters,"------->filters")
    const saveFilers = () => {
      setFlag(false);
      let obj = {}
      obj["field_id"] = dataIndexWithoutIdn;
      obj["operator"] = operator;
      obj["values"] = Array.isArray(valueForFilters) ? valueForFilters : [valueForFilters];
      setFilters(prevArray => [...prevArray, obj]);
      let finalFilterArr = [];
      finalFilterArr.push(obj);
      let arr = [...finalFilters];
      let newArrayForFinalFilters = arr.concat(finalFilterArr);
      setFinalFilters(newArrayForFinalFilters);
      let filterArr = [...filters];
      const selectedField = headerTab.fields.find(field => field.ad_field_id === dataIndexWithoutIdn);
      let selectedValues ;
      valueDropdown.map(res=>{
        if(res.RecordID == valueForFilters){
          selectedValues = res.Name
        }
      })
      let newArrayForFilters = filterArr.concat([`${selectedField.name} ${operator} ${selectedValues}`])
     setFinalFilters(newArrayForFilters)
      // setFilters(prevArray => [...prevArray, arr]);
      setColumnForFilters('');
      setOperator('in');
      // setValueForFilters([]);
      setValueItem('');
      // setValueDropdown([]);
      setVisible(false);
    } 

    const handleColumnChange = (newValue) => {
      const selectedField = headerTab.fields.find(field => field.ad_field_id === newValue);
      if (selectedField) {
        setSelectedBaseRefId(selectedField.nt_base_reference_id);
        setDataIndexWithoutIdn(selectedField.ad_field_id)
      }
      setColumnForFilters(newValue);
      setValueDropdown([]);
      setValueForFilters([]);
      setValueItem('');
    };
    

    const content = (
      <>
          <Select
            showSearch
            placeholder={'column'}
            style={{ width: 300 }}
            value={columnForFilters}
            filterOption={ (input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())}
            onChange={handleColumnChange}
          >
            {headerTab.fields.map((item) => {
              if (
                item.nt_base_reference_id !== "11" &&
                item.nt_base_reference_id !== "22" &&
                item.nt_base_reference_id !== "15" &&
                item.nt_base_reference_id !== "16"
              ) {
                return (
                  <Option key={item.ad_field_id}>{item.name}</Option>
                )
              }
            })}
          </Select>
          <br/>
          <Select
            showSearch
            placeholder={'operator'}
            style={{ width: 300 }}
            value={operator}
            onChange={(newValue) => setOperator(newValue)}
          >
            <Option key={"IN"}>in</Option>
            <Option key={"NOTIN"}>Not in</Option>
          </Select>
          <br/>
          <Spin spinning={filterLoading}>
          {selectedBaseRefId === "17" || selectedBaseRefId === "19" ?
           <Select
           className="formView"
             showSearch
             style={{ width: "100%",borderRadius:"5px" }}
             onInputKeyDown={onSearch}
             onFocus={getValueForFilters}
             onChange={(newValue) => setValueForFilters(newValue)}
             optionFilterProp="children"
             allowClear={true}
           >
             {valueDropdown.map((option, index) => (
               <Option key={`${index}-${option.Name}`} value={option.RecordID}>
                 {option.Name}
               </Option>
             ))}
           </Select>:
          <Input
          id="filterText"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          style={{ marginBottom: 3, width: 200 }}
        />}
          </Spin>
        <br/>
        <br/>
        <Button style={{width: "150px", height: "32px" }} onClick={onClose}>
          Close
        </Button>
        <Button style={{backgroundColor: "rgb(8 158 164)", color: "white", width: "150px", height: "32px", float: "right" }} onClick={saveFilers}>
          Save
        </Button>
    </>
    );

    const drag = (ev) => {
        ev.dataTransfer.setData("text", ev.target.innerText);
    };

    const allowDrop = (ev) => {
        ev.preventDefault();
    };

    const dropRow = (ev) => {
        ev.preventDefault();
        let dropItem = ev.dataTransfer.getData("text");
        data.forEach(item => {
          if (item.displayName === dropItem) {
            dropItem = item.key;
          }
        });
        let arr = [...rowValue];
        if (arr.includes(dropItem)) {

        } else {
            arr.push(dropItem);
            setRowValue(arr);
            setFlag(false);
            setTableColumnData([]);
            setBodyData([]);
        };
    };

    const dropColumn = (ev) => {
        ev.preventDefault();
        let dropItem = ev.dataTransfer.getData("text");
        data.forEach(item => {
          if (item.displayName === dropItem) {
            dropItem = item.key;
          }
        });
        let arr = [...columnValue];
        if (arr.includes(dropItem)) {

        } else {
            arr.push(dropItem);
            setColumnValue(arr);
            setFlag(false);
            setTableColumnData([]);
            setBodyData([]);
        };
    };

    const handleChange = (value) => {
        setLimit(value);
        setFlag(false);
        setTableColumnData([]);
        setBodyData([]);
    };

    const handleVisibleChange = (visible) => {
      setVisible(visible);
    };

    const handleDateValue = (value) => {
      setDateValue(value);
    };

    const currentChanges = (dates, dateStrings) => {
      setFlag(false);
      if (dates === null) {
        setCurrentFromDate(null);
        setCurrentToDate(null);
        setCurrentDateValue([]);
      } else {
        setCurrentFromDate(dateStrings[0]);
        setCurrentToDate(dateStrings[1]);
        setCurrentDateValue(dates);
      }
    };

    

    const checkboxChange = (e) => {
      setCheckBox(e.target.checked);
      setFlag(false);
    };

    const previousChanges = (dates, dateStrings) => {
      setFlag(false);
      setPreviousFromDate(dateStrings[0]);
      setPreviousToDate(dateStrings[1]);
      setPreviousDateValue(dates);
    };

    const handleDownloadCsv = () => {
      if (rowValue.length > 0 || columnValue.length > 0) {
        setFlag(true);
        setLoading(true);
        let arr = [];
        let comparableFlag = "Y";
        arr.push(
          currentFromDate,
          currentToDate,
          previousFromDate,
          previousToDate
        );
        for (let index = 0; index < arr.length; index++) {
          if (arr[index] === '' || arr[index] === null) {
            comparableFlag = "N"
          }
        };
        if (comparableFlag === "Y") {
          setCompareFlag(true);
        } else {
          setCompareFlag(false);
        };
        let metricsArray = [];
        for (let index = 0; index < measuresValue.length; index++) {
          let obj = {};
          obj["type"] = "SUM";
          obj["column"] = measuresValue[index];
          metricsArray.push(obj);
        };
        Axios({
          url: `${url}/getComparableTable`,
          method: 'POST',
          responseType: 'stream',
          crossDomain: true,
          data: {
            "table": dimensionsName,
            "limit": "1000000",
            "resultFormat": "csv",
            "rows" : rowValue,
            "columns": columnValue,
            "metrics": metricsArray,
            "comparableDates": {
              "isComparable": checkbox === true ? (comparableFlag === "Y" ? true : false) : false,
              "currentStartDate": currentFromDate !== '' ? currentFromDate : null,
              "currentEndDate": currentToDate !== '' ? currentToDate : null,
              "previousStartDate": checkbox === true ? (previousFromDate !== '' ? previousFromDate : null) : null,
              "previousEndDate": checkbox === true ? (previousToDate !== '' ? previousToDate : null) : null
            },
            "filters": finalFilters,
            "database": "pg",
            "date_column" : dateValue
          },
        }).then(response => {
          const link = document.createElement('a');
          link.href = response.data;
          link.click();
          setFlag(false);
          setLoading(false);
        }, error => {
          setLoading(false);
          setFlag(false);
          message.error(error.message);
        })
      } else {
        message.warning("Please select atleast one Row");
        setFlag(false);
      };
    };

    const fieldIdToColumnNameMap = {};
    headerTab?.fields?.forEach((item) => {
     fieldIdToColumnNameMap[item.ad_field_id] = item.name;
  });

  const onRun = async () => {
    let comparableFlag = "Y";
    setLoading(true);
    const headerTabData = windowDefinition.tabs[windowDefinition.tabs.findIndex((tab) => tab.tablevel === "0")];
  
    const stringifyWithNulls = (obj) => {
      const jsonString = JSON.stringify(obj, (key, value) => (value === null ? 'null' : value));
      return jsonString.replace(/"null"/g, 'null');
    };
  
    let obj = {
      limit: limit,
      rows: rowValue,
      columns: columnValue,
      measures: measuresValue,
      filters: filters.length > 0 ? filters : null,
      comparableDates: {
        isComparable: checkbox === true ? (comparableFlag === "Y" ? true : false) : false,
        currentStartDate: currentFromDate !== '' ? currentFromDate : null,
        currentEndDate: currentToDate !== '' ? currentToDate : null,
        previousStartDate: checkbox === true ? (previousFromDate !== '' ? previousFromDate : null) : null,
        previousEndDate: checkbox === true ? (previousToDate !== '' ? previousToDate : null) : null
      }
    };
  
    const parameters = stringifyWithNulls(obj);
    let data = await getPivotData(windowId, headerTabData.ad_tab_id, parameters);
    setLoading(false);
  
    if (typeof data === 'string') {
      data = JSON.parse(data);
    }
    if (comparableFlag === "Y") {
      setCompareFlag(true);
    } else {
      setCompareFlag(false);
    }
  
    if (Array.isArray(data)) {
      if (data.length > 0) {
        let rowData = [];
        data.map(item => {
          let arr = [];
          rowValue.map(rowFieldId => {
            const rowName = fieldIdToColumnNameMap[rowFieldId];
            arr.push(item[rowName]);
          });
          rowData.push(arr);
        });
  
        let columnData = [];
        data.map(item => {
          let arr = [];
          columnValue.forEach(columnFieldId => {
            const columnName = fieldIdToColumnNameMap[columnFieldId];
            arr.push(item[columnName]);
          });
          columnData.push(arr);
        });
  
        let hashMap1 = {};
        rowData.forEach((arr) => {
          hashMap1[arr.join("|")] = arr;
        });
        let finalRowData = Object.keys(hashMap1).map((k) => {
          return hashMap1[k];
        });
  
        let hashMap2 = {};
        columnData.forEach((arr) => {
          hashMap2[arr.join("|")] = arr;
        });
        let finalColumnData = Object.keys(hashMap2).map((k) => {
          return hashMap2[k];
        });
  
        let bodyArr = [...finalRowData];
        let summaryArray = [];
  
        // Initialize totals for each measure
        let totals = measuresValue.map(() => ({
          previous: 0,
          current: 0
        }));
  
        finalColumnData.forEach((column, index1) => {
          finalRowData.forEach((row, index2) => {
            let arr = [];
            data.forEach((item) => {
              let key = "Y";
  
              // Check row values
              rowValue.forEach((rowFieldId, index3) => {
                const rowName = fieldIdToColumnNameMap[rowFieldId];
                if (item[rowName] !== row[index3]) {
                  key = "N";
                }
              });
  
              // Check column values
              if (key !== "N") {
                columnValue.forEach((columnFieldId, index4) => {
                  const columnName = fieldIdToColumnNameMap[columnFieldId];
                  if (item[columnName] !== column[index4]) {
                    key = "N";
                  }
                });
              }
  
              // If row and column match
              if (key === "Y") {
                if (checkbox === false || (checkbox === true && comparableFlag === "N")) {
                  // Handle non-compare case
                  measuresValue.forEach((value, measureIndex) => {
                    const measureName = fieldIdToColumnNameMap[value];
                    let measureValue = item[measureName];
                    arr.push(measureValue);
                    // Accumulate totals for summary
                    totals[measureIndex].current += measureValue || 0;
                  });
                } else if (checkbox === true && comparableFlag === "Y") {
                  // Handle compare case (both current and previous values)
                  measuresValue.forEach((value, measureIndex) => {
                    const measureName = fieldIdToColumnNameMap[value];
                    let previousValue = item[`Previous ${measureName}`];
                    let currentValue = item[`Current ${measureName}`];
                    arr.push(previousValue);
                    arr.push(currentValue);
                    // Accumulate totals for summary
                    totals[measureIndex].previous += previousValue || 0;
                    totals[measureIndex].current += currentValue || 0;
                  });
                }
              }
            });
  
            // Populate bodyArr for both non-compare and compare cases
            if (arr.length > 0) {
              if (checkbox === false || (checkbox === true && comparableFlag === "N")) {
                measuresValue.forEach((value, index) => {
                  bodyArr[index2].push(arr[index]);
                });
              } else if (checkbox === true && comparableFlag === "Y") {
                measuresValue.forEach((value, index) => {
                  bodyArr[index2].push(arr[2 * index + 0]);  // Previous value
                  bodyArr[index2].push(arr[2 * index + 1]);  // Current value
                });
              }
            } else {
              // Handle empty data (push empty cells for no data)
              if (checkbox === true && comparableFlag === "Y") {
                measuresValue.forEach(() => {
                  bodyArr[index2].push('');
                  bodyArr[index2].push('');
                });
              } else {
                measuresValue.forEach(() => {
                  bodyArr[index2].push('');
                });
              }
            }
          });
        });
  
        // Create summary row (totals)
        rowValue.forEach((item, index) => {
          if (rowValue.length - 1 === index) {
            summaryArray.splice(index, 0, "Total");
          } else {
            summaryArray.splice(index, 0, "");
          }
        });
  
        // Add totals to summary row
        if (checkbox === false || (checkbox === true && comparableFlag === "N")) {
          totals.forEach((total) => {
            summaryArray.push(total.current);  // Only current totals
          });
        } else if (checkbox === true && comparableFlag === "Y") {
          totals.forEach((total) => {
            summaryArray.push(total.previous);  // Previous totals
            summaryArray.push(total.current);   // Current totals
          });
        }
  
        bodyArr.unshift(summaryArray);  // Add summary as the first row
  
        let columnsForTable = [];
        for (let index = 0; index < finalColumnData[0].length; index++) {
          let arr = [];
          finalColumnData.forEach((column) => {
            arr.push(column[index]);
          });
          columnsForTable.push(arr);
        }
  
        let arrForSum = [];
        finalColumnData.forEach((column) => {
          arrForSum.push(column[0]);
        });
        let finalArrForSum = [];
        finalArrForSum.push(arrForSum);
  
        let colorArr = [];
        rowValue.forEach(() => {
          colorArr.push('');
        });
        finalColumnData.forEach((column, index) => {
          if (checkbox === false) {
            measuresValue.forEach(() => {
              if (index % 2 === 0) {
                colorArr.push('#f3f3f3ff');
              } else {
                colorArr.push('');
              }
            });
          } else if (checkbox === true && comparableFlag === "Y") {
            measuresValue.forEach(() => {
              if (index % 2 === 0) {
                colorArr.push('#f3f3f3ff');
                colorArr.push('#f3f3f3ff');
              } else {
                colorArr.push('');
                colorArr.push('');
              }
            });
          }
        });
  
        setTableColumnData(columnsForTable);
        setSumForTable(finalArrForSum);
        setBodyData(bodyArr);
        setColorArray(colorArr);
        setLoading(false);
      }
    }
  };
  

    
    const hideMeasures = () => {
      if (isVerticalMeasure) {
        setVerticalMeasure(false);
      } else {
        setIsMeasure(false);
      }
    };
  
    const onMinimise = () => {
      if (isMeasure) {
        setIsMeasure(false);
        setMinimise(false);
        setVerticalMeasure(true);
      } else {
        setVerticalMeasure(false);
        setMinimise(false);
      }
    };

    const onMaximise = () => {
      if (isVerticalMeasure) {
        setIsMeasure(true);
        setMinimise(true);
      } else {
        setIsMeasure(false);
        setMinimise(true);
      }
    };
  
    const hideDimensions = () => {
      setDimensions(false);
    };
  
    const showDimensions = () => {
      setDimensions(true);
      setCustomise(false);
    };

    const showMeasures = () => {
      if (!isMinimise) {
        setVerticalMeasure(true);
        setIsMeasure(false);
      } else {
        setVerticalMeasure(false);
        setIsMeasure(true);
      }
    };

    const showCustomise = () => {
      setCustomise(true);
      setDimensions(false);
    };

    const hideCustomise = () => {
      setCustomise(false);
    };

    const operations = <img style={{ marginTop: "-8px", cursor: "pointer" }} src={MeasureClose} alt="MeasureClose" onClick={hideDimensions} />;

    const renderThumb = ({ style, ...props }) => {
      const thumbStyle = {
        backgroundColor: "#c1c1c1",
        borderRadius: "5px",
        width: "8px",
      };
      return <div style={{ ...style, ...thumbStyle }} {...props} />;
    };
  
    const renderView = ({ style, ...props }) => {
      const viewStyle = {
        color: "#00000",
      };
      return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
    };

    const responsiveDesignRow = {
      xxl: 24,
      xl: 24,
      lg: 24,
      xs: 24,
      sm: 24,
      md: 24,
    };
  
    const responsiveDesignColumn = {
      xxl: 19,
      xl: 19,
      lg: 19,
      xs: 19,
      sm: 19,
      md: 19,
    };

    // const responsiveDesignFilter1 = {
    //   xxl: 16,
    //   xl: 16,
    //   lg: 16,
    //   xs: 16,
    //   sm: 16,
    //   md: 16,
    // };
  
    const responsiveDesignFilter = {
      xxl: 21,
      xl: 21,
      lg: 21,
      xs: 21,
      sm: 21,
      md: 21,
    };
  
    // const responsiveSearch1 = {
    //   xxl: 22,
    //   xl: 22,
    //   lg: 22,
    //   xs: 22,
    //   sm: 22,
    //   md: 22,
    // };
  
    const responsiveSearch = {
      xxl: 8,
      xl: 8,
      lg: 8,
      xs: 8,
      sm: 8,
      md: 8,
    };
  
    const responsiveDesignFour = {
      xxl: 5,
      xl: 5,
      lg: 5,
      xs: 5,
      sm: 5,
      md: 5,
    };
    const responsiveDesignButton = {
      xxl: 2,
      xl: 2,
      lg: 2,
      xs: 2,
      sm: 2,
      md: 2,
    };
  
    const responsiveDesignAddFilter = {
      xxl: 3,
      xl: 3,
      lg: 3,
      xs: 3,
      sm: 3,
      md: 3,
    };

    const handleSaveIcon = () => {
      setVisibleViewModal(true);
    };

    const saveViewName = async () => {
      try{
        setVisibleViewModal(false);
        const userData = {...globalStore.userData};
        let pivotChanges = {
          dimensionsValue : dimensionsValue,
          limit: limit,
          rowValue: rowValue,
          columnValue: columnValue,
          checkbox: checkbox,
          compareFlag: compareFlag,
          currentFromDate: currentFromDate,
          currentToDate: currentToDate,
          previousFromDate: previousFromDate,
          previousToDate: previousToDate,
          finalFilters: finalFilters,
          mainData: mainData,
          data: data,
          measuresValue: measuresValue,
          operator: operator,
          filters: filters,
          formatValue: formatValue
        };
        const stringifiedFields = JSON.stringify(pivotChanges).replace(/"/g, "'");
        const response = await upsertViews(userData.user_id, userData.cs_client_id, "7406", viewName, stringifiedFields);
        if (response) {
          if (response.title === "Success") {
            message.success(response.message);
            setViewFlag(!viewFlag);
          }
        }
      } catch(error) {
        message.error("View is not saved properly");
      };
    };
  
    const handleViewName = (e) => {
      setViewName(e.target.value);
    };

    const handleOpenDropDown = (flag) => {
      setOpenVisible(flag);
    };

    useEffect(() => {
      if (openFlag !== '') {
        onRun();
      };
    }, [openFlag]);

    const handleOpenMenu = (e) => {
      if (e.key !== openFlag) {
        const data = openData.filter(item => item.id === e.key);
        const selectedData = JSON.parse(data[0].filters.replace(/'/g, '"'));
        setCheckBox(selectedData[0].checkbox);
        setColumnValue(selectedData[0].columnValue);
        setCompareFlag(selectedData[0].comparableFlag);
        setCurrentDateValue([moment(selectedData[0].currentFromDate, dateFormat), moment(selectedData[0].currentToDate, dateFormat)]);
        setCurrentFromDate(selectedData[0].currentFromDate);
        setCurrentToDate(selectedData[0].currentToDate);
        setData(selectedData[0].data);
        setDimensionsValue(selectedData[0].dimensionsValue);
        setFilters(selectedData[0].filters);
        setFinalFilters(selectedData[0].finalFilters);
        setFormatValue(selectedData[0].formatValue);
        setLimit(selectedData[0].limit);
        setMainData(selectedData[0].mainData);
        setMeasuresValue(selectedData[0].measuresValue);
        setOperator(selectedData[0].operator);
        setPreviousDateValue([moment(selectedData[0].previousFromDate, dateFormat), moment(selectedData[0].previousToDate, dateFormat)]);
        setPreviousFromDate(selectedData[0].previousFromDate);
        setPreviousToDate(selectedData[0].previousToDate);
        setRowValue(selectedData[0].rowValue);
        setOpenVisible(false);
        setOpenFlag(e.key);
      } else {
        message.warning("Already selected this View");
      }
    };

    const openMenu = () => {
      return (
        <Menu
          key="1"
          style={{
            overflowY: "scroll",
            maxHeight: "15rem",
          }}
          onClick={handleOpenMenu}
        >
          {openData.map((item) => {
            return (
              <Menu.Item key={item.id}>
                {item.name}
              </Menu.Item>
            );
          })}
        </Menu>
      );
    };

    const handleChartsDropDown = (flag) => {
      setChartsVisible(flag);
    };

    const handleChartsMenu = (e) => {
      setChartsVisible(false);
    };

    const chartsMenu = () => {
      return (
        <Menu
          key="1"
          style={{
            overflowY: "scroll",
            maxHeight: "15rem",
          }}
          onClick={handleChartsMenu}  
        >
          <Menu.Item key={"Grouped Bar Chart"}>Grouped Bar Chart</Menu.Item>
          <Menu.Item key={"Stacked Bar Chart"}>Stacked Bar Chart</Menu.Item>
          <Menu.Item key={"Pie Chart"}>Pie Chart</Menu.Item>
        </Menu>
      );
    };

    const changeFormat = (value) => {
      setLoading(true);
      setTimeout(() => {
        setFormatValue(value);
      }, 1000);
    };

    useEffect(() => {
      let isMounted = true;
      if (formatValue !== '') {
        setTimeout(() => {
          if (isMounted) {
            setLoading(false);
          };
        }, 1000);
      };
      return () => {
        isMounted = false;
      };
    }, [formatValue]);

    useEffect(() => {
      const name = `${dimensionsValue}_${new Date().toLocaleDateString()}`;
      setFilename(name);
    }, [fileFlag]);

    const downloadSheet = () => {
      setFileFlag(!fileFlag);
    };


    return (
      <Scrollbars
      style={{
        // marginLeft:'1.5px',
        // height:"auto",
        transition: 'height 0.3s',
        boxShadow:" 0px 2px 2px 0px #0000001A"
      }}
      autoHeight
      autoHeightMax="79vh"
      hidden={false}
      hideTracksWhenNotNeeded={true}
      universal
      thumbSize={90}
      renderView={renderView}
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      >
        <Row style={{ backgroundColor: "white", borderBottom: "0.25px solid #f0f0f0" }}>
          <Col {...responsiveDesignColumn}>
            <Row>
              <Col {...(!isMeasure ? responsiveDesignRow : responsiveDesignColumn)}>
                <Col {...responsiveDesignRow}>
                  <Row>
                    <Col {...responsiveDesignAddFilter}>
                      <Row>
                        <p style={{ fontSize: "13px", fontWeight: 600, paddingLeft: "8px", marginBottom: "0px", paddingTop: "4px" }}>Filter</p>&nbsp;
                        <Popover content={content} trigger="click" title="Filters" visible={visible} onVisibleChange={handleVisibleChange}>
                          <img style={{ paddingTop: "3px" }} src={AddFilter} alt="AddFilter" />
                        </Popover>
                      </Row>
                    </Col>
                    <Col {...responsiveDesignFilter} style={{ paddingTop: "2.5px" }}>
                    <Select
                  allowClear
                  mode="multiple"
                  showArrow={false}
                  value={finalFilters}
                  style={{ width: "100%", border: "0px solid gray" }}
                  className="ant-select-selector1"
                  onChange={(newValue) => {
                    setFlag(false);
                    let arr = [];
                    let matchingId;
                    let updatedFinalFilters = [...newValue];
                    if (newValue.length > 0) {
                      newValue.forEach(res => {

                        // Convert res to lowercase for case-insensitive search
                        const lowerCaseRes = res.toLowerCase();
                        const indexOfIn = lowerCaseRes.indexOf(" in ");

                        if (indexOfIn !== -1) {
                          // Extract the substring before " in "
                          const valueBeforeIn = res.substring(0, indexOfIn).trim();
                          console.log(valueBeforeIn);
                          
                          // Find matching fields in headerTab.fields
                          const matchedFields = headerTab.fields.filter(item => item.name === valueBeforeIn);
                          
                          // Assuming there can be multiple matches, get the first match's ad_field_id
                          if (matchedFields.length > 0) {
                            matchingId = matchedFields[0].ad_field_id; // Use the first match's ad_field_id
                          }
                        }
                        
                        // Remove filters with matchingId from filters array
                        arr = filters.filter(item => item.field_id !== matchingId);
                        // Also remove from finalFilters array
                        updatedFinalFilters = updatedFinalFilters.filter(item => {
                          const lowerCaseItem = item.toLowerCase();
                          const indexOfInItem = lowerCaseItem.indexOf(" in ");
                          if (indexOfInItem !== -1) {
                            const valueBeforeInItem = item.substring(0, indexOfInItem).trim();
                            const matchedFieldsItem = headerTab.fields.filter(f => f.name === valueBeforeInItem);
                            if (matchedFieldsItem.length > 0) {
                              return matchedFieldsItem[0].ad_field_id !== matchingId;
                            }
                          }
                          return true;
                        });
                      });

                      setFilters(arr);
                      setFinalFilters(updatedFinalFilters);
                      setValueForFilters([])
                    } else {
                      setFilters([]);
                      setFinalFilters([]);
                      setValueForFilters([])
                    }
                  }}
                  maxTagCount="responsive"
                >
                  {finalFilters?.map(item => (
                    <Option key={item}>{item}</Option>
                  ))}
                </Select>{" "}
                      {isMinimise ? (
                        <img
                          style={{ paddingTop: "3px", width: "22px", position: "absolute", zIndex: 1, top: "5px", right: "5px", cursor: "pointer" }}
                          src={Minimise}
                          alt="Minimise"
                          onClick={onMinimise}
                        />
                      ) : (
                        <img
                          style={{ paddingTop: "3px", width: "22px", position: "absolute", zIndex: 1, top: "5px", right: "5px", cursor: "pointer" }}
                          src={Maximise}
                          alt="Minimise"
                          onClick={onMaximise}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
                {isMinimise ? (
                  <div>
                    <Row>
                      <Col {...responsiveDesignAddFilter}>
                        <p style={{ fontSize: "13px", fontWeight: 600, paddingLeft: "8px", marginBottom: "0px", paddingTop: "4px" }}>Rows</p>
                      </Col>
                      <Col {...responsiveDesignFilter}>
                      <Select
                      allowClear
                      mode="multiple"
                      filterOption={ (input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())}
                      onDrop={dropRow}
                      onDragOver={allowDrop}
                      showArrow={false}
                      style={{ width: "100%" }}
                      value={rowValue}
                      maxTagCount="responsive"
                      onChange={(newValue) => {
                        if (!rowValue.includes(newValue)) {
                          setRowValue(newValue);
                          setFlag(false);
                          setTableColumnData([]);
                          setBodyData([]);
                        }
                      }}
                    >
                      {headerTab?.fields?.map((item) => {
                        if (
                          item.nt_base_reference_id !== "11" &&
                          item.nt_base_reference_id !== "12" &&
                          item.nt_base_reference_id !== "16" &&
                          item.nt_base_reference_id !== "15" &&
                          item.nt_base_reference_id !== "22" &&
                          item.column_name !== null &&
                          !item.ad_fieldgroup_id
                        ) {
                          return <Option key={item.ad_field_id}>{item.name}</Option>;
                        }
                        return null; // Return null for cases that don't meet the criteria
                      })}
                    </Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col {...responsiveDesignAddFilter}>
                        <p style={{ fontSize: "13px", fontWeight: 600, paddingLeft: "8px", marginBottom: "0px", paddingTop: "4px" }}>Columns</p>
                      </Col>
                      <Col {...responsiveDesignFilter}>
                        <Select
                          // className="pivotRows"
                          allowClear
                          mode="multiple"
                          onDrop={dropColumn}
                          filterOption={ (input, option) =>
                          option.children.toLowerCase().includes(input.toLowerCase())}
                          onDragOver={allowDrop}
                          showArrow={false}
                          style={{ width: "100%" }}
                          value={columnValue}
                          maxTagCount="responsive"
                          onChange={(newValue) => {
                            if (columnValue.includes(newValue)) {
                            } else {
                              setColumnValue(newValue);
                              setFlag(false);
                              setTableColumnData([]);
                              setBodyData([]);
                            }
                          }}
                        >
                          {headerTab?.fields?.map((item) => {
                            if (
                              item.nt_base_reference_id !== "11" &&
                              item.nt_base_reference_id !== "22" &&
                              item.nt_base_reference_id !== "16" &&
                              item.nt_base_reference_id !== "15" &&
                              item.nt_base_reference_id !== "12" &&
                              item.column_name !== null &&
                              !item.ad_fieldgroup_id
                            ) {
                              return <Option key={item.ad_field_id}>{item.name}</Option>;
                            }
                          })}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    {isVerticalMeasure ? (
                      <Row style={{ paddingBottom: "8px" }}>
                        <Col {...responsiveDesignAddFilter}>
                          <p style={{ fontSize: "13px", fontWeight: 600, paddingLeft: "8px", marginBottom: "0px", paddingTop: "4px" }}>Measures</p>{" "}
                        </Col>
                        <Col {...responsiveDesignFilter}>
                          <Row>
                            <Col {...responsiveSearch} style={{ paddingRight: "2px" }}>
                              <Select
                                allowClear
                                showSearch
                                mode="multiple"
                                style={{ width: "100%", marginTop: "8px" }}
                                placeholder={"measures"}
                                filterOption={(inputValue, option) => {
                                return option.children.toLowerCase().includes(inputValue.toLowerCase())}}
                                value={measuresValue}
                                maxTagCount="responsive"
                                onChange={(newValue) => {
                                  setMeasuresValue(newValue);
                                  setFlag(false);
                                  setTableColumnData([]);
                                  setBodyData([]);
                                }}
                              >
                                {data.map((item) => {
                                  if (
                                    item.type === "real" ||
                                    item.type === "int"
                                  ) {
                                    return <Option key={item.key}>{item.displayName}</Option>;
                                  }
                                })}
                              </Select>
                            </Col>
  
                            <Col {...responsiveSearch} style={{ paddingLeft: "2px" }}>
                              <Select
                                showSearch
                                style={{ width: "100%", marginTop: "8px" }}
                                placeholder={"limit"}
                                value={limit}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                onChange={handleChange}
                              >
                                <Option value="100">100</Option>
                                <Option value="1000">1000</Option>
                                <Option value="5000">5000</Option>
                                <Option value="10000">10000</Option>
                                <Option value="20000">20000</Option>
                                <Option value="50000">50000</Option>
                              </Select>
                            </Col>
                            <Col {...responsiveDesignButton} style={{ textAlign: "right", paddingTop: "10PX", paddingRight: "2px" }}>
                              <img style={{ marginTop: "-8px", cursor: "pointer" }} src={MeasureClose} alt="MeasureClose" onClick={hideMeasures} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : null}
                  </div>
                )}
              </Col>
              {isMeasure ? (
                <Col {...responsiveDesignFour} style={{ borderLeft: "0.25px solid #f0f0f0", borderRight: "0.25px solid #f0f0f0", padding: "8px" }}>
                  <Row>
                    <Col {...responsiveDesignFilter}>
                      <h5>Measures</h5>{" "}
                    </Col>
                    <Col {...responsiveDesignAddFilter} style={{ textAlign: "right", paddingRight: "4px" }}>
                      <img style={{ marginTop: "-8px", cursor: "pointer" }} src={MeasureClose} alt="MeasureClose" onClick={hideMeasures} />
                    </Col>
                  </Row>
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder={"measures"}
                    value={measuresValue}
                    filterOption={(inputValue, option) => {
                      return option.children.toLowerCase().includes(inputValue.toLowerCase())}}
                    maxTagCount="responsive"
                    onChange={(newValue) => {
                      setMeasuresValue(newValue);
                      setFlag(false);
                      setTableColumnData([]);
                      setBodyData([]);
                    }}
                  >
                    {headerTab?.fields?.map((item) => {
                            if (
                              item.nt_base_reference_id === "11" ||
                              item.nt_base_reference_id === "22"  || 
                              item.nt_base_reference_id === "12"&&
                              item.column_name !== null &&
                              !item.ad_fieldgroup_id
                            ) {
                              return <Option key={item.ad_field_id}>{item.name}</Option>;
                            }
                          })}
                  </Select>
                  <Select
                    showSearch
                    style={{ width: "100%", marginTop: "8px" }}
                    placeholder={"limit"}
                    value={limit}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                    onChange={handleChange}
                  >
                    <Option value="20">20</Option>
                    <Option value="1000">1000</Option>
                    <Option value="5000">5000</Option>
                    <Option value="10000">10000</Option>
                    <Option value="20000">20000</Option>
                    <Option value="50000">50000</Option>
                  </Select>
                </Col>
              ) : null}
            </Row>
          </Col>
  
          <Col {...responsiveDesignFour} style={{ backgroundColor: "white" }}>
            <Col {...responsiveDesignRow}>
              <Menu style={{ display: "flex", padding: "8px", borderBottom: "0.25px solid #f0f0f0" }}>
              <SubMenu
                  key="sub1"
                  icon={
                    <Dropdown trigger={["click"]} overlay={openMenu} onVisibleChange={handleOpenDropDown} visible={openVisible}>
                      <Tooltip title="Open" placement="bottom">
                        <img style={{ marginTop: "-5px" }} src={Open} alt="Open" />{" "}
                      </Tooltip>
                    </Dropdown>
                  }
                />
                <SubMenu
                  key="sub2"
                  icon={
                    <Tooltip title="Save" placement="bottom">
                      <img style={{ marginTop: "-5px" }} src={SaveIcon} alt="SaveIcon" onClick={handleSaveIcon} />
                    </Tooltip>
                  }
                />
                <SubMenu
                  key="sub3"
                  icon={
                    <Dropdown trigger={["click"]} overlay={chartsMenu} onVisibleChange={handleChartsDropDown} visible={chartsVisible}>
                      <Tooltip title="Charts" placement="bottom">
                        <img style={{ marginTop: "-5px" }} src={Charts} alt="Charts" />
                      </Tooltip>
                    </Dropdown>
                  } 
                />
                <SubMenu 
                  key="sub4" 
                  icon={
                    <Tooltip title="Customise" placement="bottom">
                      <img style={{ marginTop: "-2px" }} src={Customise} alt="Customise" onClick={showCustomise} />
                    </Tooltip>
                  }
                />
                <SubMenu 
                  key="sub5" 
                  icon={
                    <Tooltip title="Measures" placement="bottom">
                      <img style={{ marginTop: "-6px", height: "14px" }} onClick={showMeasures} src={MeasureIcon} alt="MeasureIcon" />
                    </Tooltip>
                  } 
                />
                <SubMenu 
                  key="sub6" 
                  icon={
                    <Tooltip title="Dimensions" placement="bottom">
                      <img style={{ marginTop: "-5px" }} onClick={showDimensions} src={DimensionIcon} alt="DimensionIcon" />
                    </Tooltip>
                  } 
                />
                {flag === true ? 
                  <SubMenu
                    key="sub7"
                    icon={
                      <Tooltip title="Download" placement="bottom">
                        <div onClick={downloadSheet}>
                          {/* <ReactHTMLTableToExcel
                              id="test-table-xls-button"
                              className="download-table-xls-button"
                              table="table-to-xls"
                              filename={filename}
                              sheet="tablexls"
                          /> */}
                        </div>
                      </Tooltip>
                    }
                  /> : "" 
                }
                {columnValue.length === 0 ?
                  <SubMenu
                    key="sub8"
                    icon={
                      <Tooltip title="Download as csv" placement="bottom">
                        <DownloadOutlined onClick={handleDownloadCsv} />
                      </Tooltip>
                    }
                  /> : ""
                }
                {/* <SubMenu 
                  key="sub9" 
                  icon={
                    <Tooltip title="Run" placement="bottom">
                      <img style={{ paddingTop: "0px", width: "32px", marginLeft: "-6px", cursor: "pointer" }} src={RunButton} alt="RunButton" onClick={onRun} />
                    </Tooltip>
                  }
                /> */}
              </Menu>
            </Col>
            <Col {...responsiveDesignRow} style={{ textAlign: 'center' }} >
              <Tooltip title="Run" placement="bottom">
                {/* <img style={{ paddingTop: "12px", width: "32px", cursor: "pointer" }} src={RunButton} alt="RunButton" onClick={onRun} /> */}
                <Button style={{ cursor: "pointer", backgroundColor: "#089EA4", width: "180px", height: "33px", color: "white", marginTop: "12px" }} onClick={onRun} >
                  <img src={RunButton} alt="RunButton" />
                  Run
                </Button>
              </Tooltip>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col {...(!isDimension && !isCustomise ? responsiveDesignRow : responsiveDesignColumn)}>
            {flag === false ? (
              <Card>
                <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={loading}>
                <Table bordered responsive size="sm" id="table-to-xls">
                    <thead key={"head"}>
                      <tr key={"head1"}>
                        {rowValue.map((item) => {
                          return data.map(item2 => {
                            return item2.ad_field_id === item ?
                              <th rowSpan={columnValue.length + 2} key={item}>
                                {item2.name}
                              </th> :
                              ""
                          })
                        })}
                      </tr>
                      {tableColumnData.map((column, index) => {
                        return (
                          <tr key={index}>
                            {column.map((item, i) => {
                              const matchingField = headerTab.fields.find(field => 
                                field.Values && field.Values.some(value => value.key === item)
                              );

                              let displayValue = item;
                              if (matchingField) {
                                const matchingValue = matchingField.Values.find(value => value.key === item);
                                if (matchingValue) {
                                  displayValue = matchingValue.value;
                                }
                              }

                              if (checkbox === false || (checkbox === true && compareFlag === false)) {
                                return (
                                  <th style={{ backgroundColor: i % 2 === 0 ? "#f3f3f3ff" : "" }} colSpan={measuresValue.length} key={i}>
                                    {displayValue}
                                  </th>
                                );
                              } else if (checkbox === true && compareFlag === true) {
                                return (
                                  <th style={{ backgroundColor: i % 2 === 0 ? "#f3f3f3ff" : "" }} colSpan={measuresValue.length * 2} key={i}>
                                    {displayValue}
                                  </th>
                                );
                              }
                            })}
                          </tr>
                        );
                      })}
                      <tr key={"head2"}>
                        {sumForTable.map((column) =>
                          column.map((item, index) => (
                            <>
                              {measuresValue.map((value, i) => {
                                return data.map(item2 => {
                                  if (item2.ad_field_id === value) {
                                    if (checkbox === false || (checkbox === true && compareFlag === false)) {
                                      return (
                                        <th style={{ backgroundColor: index % 2 === 0 ? "#f3f3f3ff" : "" }} key={`${index}-${i}`}>
                                          {item2.name}
                                        </th>
                                      );
                                    } else if (checkbox === true && compareFlag === true) {
                                      return (
                                        <>
                                          <th style={{ backgroundColor: index % 2 === 0 ? "#f3f3f3ff" : "" }} key={`${index}-${i}1`}>
                                            {item2.name} (Previous)
                                          </th>
                                          <th style={{ backgroundColor: index % 2 === 0 ? "#f3f3f3ff" : "" }} key={`${index}-${i}2`}>
                                            {item2.name} (Current)
                                          </th>
                                        </>
                                      );
                                    }
                                  }
                                })
                              })}
                            </>
                          ))
                        )}
                      </tr>
                    </thead>
                    <tbody key={"body"}>
                      {bodyData.map((body, index) => {
                        return (
                          <tr key={index}>
                            {body.map((item, i) => {
                              return colorArray.map((color, j) => {
                                if (i === j) {
                                  return (
                                    <td style={{ fontWeight: index === 0 ? "bold" : "", backgroundColor: color === "#f3f3f3ff" ? "#f3f3f3ff" : "" }} key={`${index}-${i}`} className={isNaN(item) ? "pivot-left" : "pivot-right"}>
                                      {formatValue === "AdaptiveFormat" ?
                                        <AdaptiveFormat precisionValue={2}>{item}</AdaptiveFormat> : 
                                      formatValue === "NumberFormat" ?
                                        (item !== null && item !== undefined ? item.toLocaleString('en-US') : item) :
                                        item
                                      }
                                    </td>
                                  )
                                }
                              })
                           })}
                          </tr>
                        );
                      })}
                    </tbody>{" "}
                  </Table>
                </Spin>
              </Card>
            ) : (
              <Col {...responsiveDesignRow} />
            )}
          </Col>
          <Col {...responsiveDesignFour}>
            {isDimension ? (
              <Col
                {...responsiveDesignRow}
                style={{
                  marginTop: `${isMeasure && !isVerticalMeasure ? "0px" : !isVerticalMeasure && !isMeasure && isMinimise ? "0px" : "0px"}`,
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "white",
                }}
              >
                <Tabs tabBarExtraContent={operations}>
                  {/* <TabPane style={{ border: "0px solid gray" }} tab="Dimensions" key="1">
                    <Spin spinning={mainDataLoading}>
                      <Select
                        showSearch
                        style={{
                          width: "100%",
                        }}
                        value={dimensionsValue}
                        onFocus={getDimensionData}
                        onChange={handleDimensions}
                      >
                        {mainData.map(item => {
                          return <Option key={item.csDWTaskId}>{item.name}</Option>
                        })}
                      </Select>
                    </Spin>
                    <Scrollbars
                      style={{
                        height: "65vh",
                      }}
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={200}
                      thumbSize={90}
                      renderView={renderView}
                      renderThumbHorizontal={renderThumb}
                      renderThumbVertical={renderThumb}
                    >
                      <Spin indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px", color: "#1648aa" }} />} spinning={load}>
                        <Menu mode="inline">
                          {data.map((item, index) => {
                            if (
                              item.type !== "int" &&
                              item.type !== "real"
                            ) {
                              return (
                                <Menu.Item className="testingStyle" draggable="true" onDragStart={drag} key={index}>
                                  <span style={{ color: "#080707", fontWeight: "normal", fontSize: "12px" }}>{item.displayName}</span>
                                </Menu.Item>
                              );
                            }
                          })}
                        </Menu>
                      </Spin>
                    </Scrollbars>
                  </TabPane> */}
                  <TabPane style={{ border: "0px solid gray" }} tab="Date Range" key="2">
                    <div style={{ height: "65vh" }}>
                      <Select
                        showSearch
                        allowClear
                        filterOption={(inputValue, option) => {
                          return option.children.toLowerCase().includes(inputValue.toLowerCase())}}
                        style={{
                          width: "100%",
                        }}
                        value={dateValue}
                        onChange={handleDateValue}
                      >
                        {headerTab.fields.map((item) => {
                            if (
                              item.nt_base_reference_id === "15" || item.nt_base_reference_id === "16"
                            ) {
                              return<Option key={item.ad_field_id}>{item.name}</Option>;
                            }
                          })}
                      </Select>
                      {dateValue !== '' && dateValue !== null && dateValue !== undefined ?
                        <>
                          <RangePicker key={"1"} format={dateFormat} value={currentDateValue} onChange={currentChanges} />
                          <Checkbox style={{ marginTop: "8px" , marginBottom: "8px" }} checked={checkbox} onChange={checkboxChange}>Compare?</Checkbox>
                          {checkbox === true ? <RangePicker key={"2"} value={previousDateValue} format={dateFormat} onChange={previousChanges} /> : ""}{" "}
                        </> :
                        ""
                      }
                    </div>
                  </TabPane>{" "}
                </Tabs>
              </Col>
            ) : 
            isCustomise ? (
              <Col
                {...responsiveDesignRow}
                style={{
                  marginTop: `${isMeasure && !isVerticalMeasure ? "-52px" : !isVerticalMeasure && !isMeasure && isMinimise ? "-52px" : "0px"}`,
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "white",
                }}
              >
                <Scrollbars
                      style={{
                        height: "75vh",
                      }}
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={200}
                      thumbSize={90}
                      renderView={renderView}
                      renderThumbHorizontal={renderThumb}
                      renderThumbVertical={renderThumb}
                >
                <img style={{ marginTop: "-8px", cursor: "pointer", float: "right" }} src={MeasureClose} alt="MeasureClose" onClick={hideCustomise} />
                <h3>Customise</h3>
                <br/>
                <h5>Value Format</h5>
                <Select
                  showSearch
                  allowClear
                  style={{
                    width: "100%",
                  }}
                  value={formatValue}
                  onChange={changeFormat}
                >
                    <Option value="AdaptiveFormat">Adaptive Format</Option>
                    <Option value="NumberFormat">Number Format</Option>
                </Select>
                </Scrollbars>
              </Col>
            ) : null}
          </Col>
        </Row>
        <Modal
              visible={visibleViewModal}
              title={"Create New Grid View"}
              onCancel={() => { setVisibleViewModal(false); setViewName(''); }}
              getContainer={false}
              footer={[
                <Button onClick={() => { setVisibleViewModal(false); setViewName(''); }}>
                  Cancel
                </Button>,
                <Button style={{ backgroundColor: "rgb(8 158 164)", color: "white", width: "93px", height: "33px" }} onClick={saveViewName}>
                  Save
                </Button>
              ]}
            >
              <Input placeholder="Enter View Name" allowClear onChange={handleViewName}/>
        </Modal>
      </Scrollbars>
    );
}

export default CwAnalytics;