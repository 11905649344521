import React, { useState, useEffect, useMemo } from "react";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useHistory } from "react-router";

function MapView(props) {
  const history = useHistory();
  const [markers, setMarkers] = useState([]);
  const [activeMarkerIds, setActiveMarkerIds] = useState([]); // Track multiple active markers
  
  // Memoize the mapViewConfiguration to prevent recalculating it on every render
  const mapViewConfiguration = useMemo(() => {
    return props.headerTab?.mapview_configuration ? JSON.parse(props.headerTab.mapview_configuration) : null;
  }, [props.headerTab]);

  // Memoize the mapsData to ensure it only updates when sendDataToCard changes
  const mapsDayta = useMemo(() => props.sendDataToCard, [props.sendDataToCard]);

  useEffect(() => {
    const fetchData = async () => {
      if (mapViewConfiguration) {
        const { longitude_id, latitude_id } = mapViewConfiguration;

        const markerData = mapsDayta?.map(record => {
          const lng = record[longitude_id];
          const lat = record[latitude_id];
          if (lng && lat) {
            return {
              id: record.recordId,
              name: record.recordTitlesData[0]?.titleName || "Unknown",
              info: record.recordRow1Data.map(row => row.recordRow1Name),
              position: {
                lat: parseFloat(lat),
                lng: parseFloat(lng)
              }
            };
          }
          return null;
        }).filter(marker => marker !== null);

        // Only update the state if the marker data changes
        setMarkers(markerData || []);
        setActiveMarkerIds(markerData?.map(marker => marker.id) || []); // Activate all markers by default
      }
    };

    fetchData();
  }, [mapsDayta, mapViewConfiguration]); // Only run when mapsDayta or mapViewConfiguration change

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_APIKEY // Add your Google Maps API key here
  });

  const handleMarkerClick = (markerId) => {
    setActiveMarkerIds((prev) =>
      prev.includes(markerId) ? prev.filter(id => id !== markerId) : [...prev, markerId]
    );
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      mapContainerStyle={{ width: "100vw", height: "100vh" }}
    >
      {markers.map(({ id, name, position, info }) => (
        <Marker
          key={id}
          position={position}
          onClick={() => handleMarkerClick(id)}
        >
          {activeMarkerIds.includes(id) && (
            <InfoWindow
              position={position}
              onCloseClick={() => setActiveMarkerIds(prev => prev.filter(markerId => markerId !== id))}
            >
              <div onClick={() => { history.push(`/window/${props.headerTab.ad_window_id}/${id}`) }}>
                <h3>{name}</h3>
                <ul>
                  {info.map((infoItem, index) => (
                    <li key={index}>{infoItem}</li>
                  ))}
                </ul>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </GoogleMap>
  );
}

export default MapView;
