import React, { useState, useEffect, Fragment } from "react";
import ListWindowHeader from "./ListWindowHeader";
import ListWindowLines from "./ListWindowLines";
import { useWindowContext, useGlobalContext } from "../../lib/storage";
import { LoadingOutlined } from "@ant-design/icons";
import { getWindowInitializationData } from "../../services/generic";
import { Spin } from "antd";
import { formatDisplayField } from "../window/windowUtilities";
import { FieldReference } from "../../lib/fieldReference";
import ListWindowFooter from "./ListWindowFooter";
import MapView from "./MapsView";

const ListWindow = ({flag}) => {
  const { windowStore, setWindowStore } = useWindowContext();
  const { globalStore } = useGlobalContext();
  const windowDefinition = { ...windowStore.windowDefinition };
  const [searchKey, setSearchKey] = useState();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [loading,setLoading] = useState(false);
  const [sendDataToCard, setSendDataToCard] = useState([]);
  const [filterArray, setFilterArray] = useState({});
  const [cardsIdentifiers, setCardsIdentifiers] = useState([]);
  const [lastRefreshed, setLastRefreshed] = useState();
  const [pivotView,setPivotView] = useState(false);
  const [mapView,setMapView] = useState(false);
  const [timeLineView,setTimeLineView] = useState(false);
  const [viewsFlag,setViewsFlag] = useState(false);
  const [recordsDataMap,setRecordsDataMap] = useState();
  const [colsDataMap,setColsDataMap] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [windowLoading, setWindowLoading] = useState(false);
  const [clearFiltersFlag,setClearFiltersFlag] = useState(false)
  const [kanBanCardFlag, setKanBanCardFlag] = useState(false);
  const [calenderView,setCalenderView] = useState(false)
  const [treeViewFlag, setTreeViewFlag] = useState(() => {
    if (windowDefinition.treeView === undefined || windowDefinition.treeView === false) {
      return false;
    } else {
      return true;
    }
  });
  const [expandTreeViewFlag, setExpandTreeViewFlag] = useState(false);
  const [collapseTreeViewFlag, setCollapseTreeViewFlag] = useState(false);
  const [columns, setColumns] = useState([]);
  const [filterFlag, setFilterFlag] = useState(false);
  const [headerTab,setHeaderTab] = useState([])
  const [recordsWithMap,setRecordsWithMap] = useState([])
  const [hideAndShowData, setHideAndShowData] = useState([]);
  const [hideAndShowTitles, setHideAndShowTitles] = useState([]);
  const [summaryData, setSummaryData] = useState(() => {
    if (windowDefinition.summary === undefined || windowDefinition.summary === {}) {
      return {};
    } else {
      return windowDefinition.summary;
    }
  });
  const [filters, setFilters] = useState([]);
  const [dropDownValue,setDropDownValue]=useState(false)
  const [viewFilters, setViewFilters] = useState('');
  const [treeSearchInput, setTreeSearchInput] = useState('');

  useEffect(() => {
    setWindowLoading(globalStore.loading);
  }, [globalStore]);

  const treeView = () => {
    setTreeViewFlag(!treeViewFlag);
  };

  useEffect(() => {
    const windowDef = { ...windowDefinition };
    if (windowDef) {
      windowDef.treeView = treeViewFlag;
      windowDef.summary = summaryData;
    }
    setWindowStore({ windowDefinition: windowDef });
  }, [treeViewFlag, summaryData]);

  const takeColumns = (column) => {
    setColumns(column);
  };

  const resetFilters = () => {
    setFilterFlag(true);
    setDropDownValue(true)
  };

  const takeSummary = (summary) => {
    setSummaryData({...summary});
  };

  const takeHideAndshowData = (data) => {
    setHideAndShowData(data);
  };

  const takeHideAndShowTitles = (data) => {
    setHideAndShowTitles(data);
  };

  const kanbanCards = () => {
    setKanBanCardFlag(!kanBanCardFlag);
  };

  const ShowListView = () => {
    setKanBanCardFlag(kanBanCardFlag);
  };

  const expandTreeView = () => {
    setExpandTreeViewFlag(!expandTreeViewFlag);
  };

  const collapseTreeView = () => {
    setCollapseTreeViewFlag(!collapseTreeViewFlag)
  }
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const innerWidth = windowSize.innerWidth

  const mapViewConfiguration = headerTab?.mapview_configuration?JSON.parse(headerTab?.mapview_configuration):null
  // const mapViewConfiguration = headerTab?.mapview_configuration ? JSON.parse(headerTab?.mapview_configuration) : null;

  useEffect(() => {
    const fetchData = async () => {
      if (mapViewConfiguration !== null && mapView) {
        const filteredRecords = recordsDataMap?.filter(record => {
          const key = mapViewConfiguration.longitude_id;
          return record[key] && record[key].trim() !== '';
        });
        setRecordsWithMap(filteredRecords);
        setCardsIdentifiers(colsDataMap[0]["listKeyAndValueArray"]);
  
        let statusBarValues = [];
        const referenceList = [];
        let headerRecordData = await getWindowInitializationData(windowDefinition.ad_window_id, headerTab.ad_tab_id);
  
        headerTab.fields.forEach(element => {
          if (element["nt_base_reference_id"] === FieldReference.List) {
            const list = element.Values;
            if (list !== undefined || list !== null) {
              Object.keys(list).forEach((key) => {
                referenceList.push(list[key]);
              });
            }
          }
          if (element.isshowninstatusbar === "Y" && element.isdisplayed === "Y" && element.isactive === "Y") {
            let titleDataValue = headerRecordData[element.ad_field_id.concat("_iden")];
            if (titleDataValue === null || titleDataValue === undefined) {
              titleDataValue = headerRecordData[element.ad_field_id];
              const refIndex = referenceList.findIndex((list) => list.key === titleDataValue);
              if (refIndex >= 0) {
                titleDataValue = referenceList[refIndex].value;
              }
            }
            if (titleDataValue === "Y") {
              titleDataValue = "Yes";
            }
            if (titleDataValue === "N") {
              titleDataValue = "No";
            }
            statusBarValues.push({
              titleName: element.name,
              amountId: element.nt_base_reference_id,
              titleValue: titleDataValue,
              ad_field_id: element.ad_field_id
            });
          }
        });
  
        let fields = [];
        if (mapViewConfiguration.show_as_per_status_bar === "Y") {
          fields = statusBarValues.map(item => item.ad_field_id).join(",");
        }
        const recordTitle = mapViewConfiguration?.show_title_as_per_record_identifier === "Y" ? headerTab?.record_identifier?.split(",") : mapViewConfiguration.title_fields_id;
        const recordRow1 = fields.length > 0 ? fields?.split(",") : mapViewConfiguration.fields_id.split(",");
  
        const temmArray = [];
        for (let k = 0; k < filteredRecords.length; k += 1) {
          const recordTitlesData = [];
          const recordRow1Data = [];
          for (const [key, value] of Object.entries(filteredRecords[k])) {
            for (let index2 = 0; index2 < recordTitle.length; index2++) {
              const element = recordTitle[index2];
              if (element === key) {
                const newData = filteredRecords[k][key.concat("_iden")];
                const newData1 = newData ? newData : filteredRecords[k][key];
  
                let matchFound = false;
                headerTab.fields.forEach(res => {
                  if (res.ad_field_id === element) {
                    if (res.nt_base_reference_id === "15" || res.nt_base_reference_id === "16") {
                      const formattedValue = formatDisplayField(newData1, res, "9");
                      recordTitlesData.push({
                        titleName: formattedValue,
                      });
                      matchFound = true;
                    } else {
                      res.Values?.forEach(tre => {
                        if (tre.key === newData1) {
                          recordTitlesData.push({
                            titleName: tre.value,
                          });
                          matchFound = true;
                        }
                      });
                    }
                  }
                });
  
                if (!matchFound) {
                  recordTitlesData.push({
                    titleName: newData1,
                  });
                }
              }
            }
  
            for (let index = 0; index < recordRow1.length; index++) {
              const element = recordRow1[index];
              if (element === key) {
                const newData = filteredRecords[k][key?.concat("_iden")];
                const newData1 = newData ? newData : filteredRecords[k][key];
  
                let matchFound = false;
                headerTab.fields.forEach(res => {
                  if (res.ad_field_id === element) {
                    if (res.nt_base_reference_id === "15" || res.nt_base_reference_id === "16") {
                      const formattedValue = formatDisplayField(newData1, res, "9");
                      recordRow1Data.push({
                        recordRow1Name: formattedValue,
                      });
                      matchFound = true;
                    } else {
                      res.Values?.forEach(tre => {
                        if (tre.key === newData1) {
                          recordRow1Data.push({
                            recordRow1Name: tre.value,
                          });
                          matchFound = true;
                        }
                      });
                    }
                  }
                });
  
                if (!matchFound) {
                  recordRow1Data.push({
                    recordRow1Name: newData1,
                  });
                }
              }
            }
          }
  
          filteredRecords[k].recordTitlesData = recordTitlesData.reverse();
          filteredRecords[k].recordRow1Data = recordRow1Data.reverse();
          temmArray.push(filteredRecords[k]);
        }
        setSendDataToCard(temmArray);
      }
    };
  
    fetchData();
  }, [mapView]);
  
  

  return (
    <Fragment>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} className="spinLoader" spin />} spinning={windowLoading}>
      <ListWindowHeader
          flag={flag}
          dropDownValue={dropDownValue}
          setSearchKey={setSearchKey}
          setLastRefreshed={setLastRefreshed}
          loading = {loading}
          treeView={treeView}
          columns={columns}
          resetFilters={resetFilters}
          takeSummary={takeSummary}
          takeHideAndshowData={takeHideAndshowData}
          hideAndShowTitles={hideAndShowTitles}
          kanbanCards={kanbanCards}
          ShowListView={ShowListView}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          setWindowLoading={setWindowLoading}
          kanbanCardFlag={kanBanCardFlag}
          filters={filters}
          takeViewFilters={setViewFilters}
          treeViewFlag={treeViewFlag}
          expandTreeView={expandTreeView}
          collapseTreeView={collapseTreeView}
          treeSearchInput={treeSearchInput}
          setTreeSearchInput={setTreeSearchInput}
          filterFlag={filterFlag}
          clearFiltersFlag={clearFiltersFlag}
          setClearFiltersFlag={setClearFiltersFlag}
          setPivotView={setPivotView}
          pivotView={pivotView}
          mapView={mapView}
          setMapView={setMapView}
          calenderView={calenderView}
          setCalenderView={setCalenderView}
          timeLineView={timeLineView}
          setTimeLineView={setTimeLineView}
          viewsFlag={viewsFlag}
          setViewsFlag={setViewsFlag}
        />
       {!mapView? <ListWindowLines
          searchKey={searchKey}
          lastRefreshed={lastRefreshed}
          treeViewFlag={treeViewFlag}
          loading={loading}
          setLoading={setLoading}
          takeColumns={takeColumns}
          filterFlag={filterFlag}
          setFilterFlag={setFilterFlag}
          summaryData={summaryData}
          hideAndShowData={hideAndShowData}
          takeHideAndShowTitles={takeHideAndShowTitles}
          kanbanCardFlag={kanBanCardFlag}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          setKanBanCardFlag={setKanBanCardFlag}
          takeFilters={setFilters}
          viewFilters={viewFilters}
          expandTreeViewFlag={expandTreeViewFlag}
          collapseTreeViewFlag={collapseTreeViewFlag}
          treeSearchInput={treeSearchInput}
          clearFiltersFlag={clearFiltersFlag}
          setClearFiltersFlag={setClearFiltersFlag}
          filterArray={filterArray}
          setFilterArray={setFilterArray}
          setHeaderTab={setHeaderTab}
          pivotView={pivotView}
          headerTab={headerTab}
          calenderView={calenderView}
          timeLineView={timeLineView}
          setViewsFlag={setViewsFlag}
          setRecordsDataMap={setRecordsDataMap}
          setColsDataMap={setColsDataMap}
        />:<MapView headerTab={headerTab} sendDataToCard={sendDataToCard} recordsWithMap={recordsDataMap}/>}
        {innerWidth>768 ? "":
        <div style={{ position: 'fixed',height:"3.5em",paddingTop:"0.7em", bottom: 0, width: '100%',backgroundColor:"white" }}>
        <ListWindowFooter
          dropDownValue={dropDownValue}
          setSearchKey={setSearchKey}
          loading = {loading}
          setLastRefreshed={setLastRefreshed}
          treeView={treeView}
          columns={columns}
          resetFilters={resetFilters}
          takeSummary={takeSummary}
          takeHideAndshowData={takeHideAndshowData}
          hideAndShowTitles={hideAndShowTitles}
          kanbanCards={kanbanCards}
          ShowListView={ShowListView}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          setWindowLoading={setWindowLoading}
          kanbanCardFlag={kanBanCardFlag}
          filters={filters}
          takeViewFilters={setViewFilters}
          treeViewFlag={treeViewFlag}
          expandTreeView={expandTreeView}
          collapseTreeView={collapseTreeView}
          treeSearchInput={treeSearchInput}
          setTreeSearchInput={setTreeSearchInput}
          filterFlag={filterFlag}
        />
        </div>}
      </Spin>
    </Fragment>
  );
};

export default ListWindow;
