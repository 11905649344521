import React, { useState, useEffect } from 'react';
import { Row, Col, DatePicker, Button } from 'antd';
import moment from 'moment';
import { useHistory } from "react-router";
import { Popover } from 'antd';
import { useWindowContext, useGlobalContext } from "../../lib/storage";

const CalenderView = (props) => {
  // console.log(props, "----->props");
  const { windowStore, setWindowStore } = useWindowContext();
  const windowDefinition = { ...windowStore.windowDefinition };
  const history = useHistory();



  const [selectedDate, setSelectedDate] = useState(moment());
  const [view, setView] = useState('month'); // Initial view

  useEffect(() => {
    setSelectedDate(moment()); // Set selected date to current date on component mount
  }, []);

  const onChange = (date) => {
    setSelectedDate(date);
  };

  const handleViewChange = (value) => {
    setView(value);
  };

  // Function to generate events for each date
  const generateEventsForDate = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');

    const eventsForDate = props.cardData
      .filter(card => Object.keys(card).some(key => key.startsWith(formattedDate)))
      .flatMap(card => {
        const dateKey = Object.keys(card).find(key => key.startsWith(formattedDate));
        return dateKey ? card[dateKey].flatMap(record => {
          // console.log('Record Row1 Data:', record.recordRow1Data);

          // Create the heading using keys and values from recordRow1Data
          const headings = Object.entries(record.recordRow1Data[0]).map(([key, value]) => ({
            title: ` ${value}`,
            recordId: record.recordId,
            title_field_color: record.title_field_color,
            isHeading: true // Indicate this is a heading
          }));

          // Flatten the nested structure of titleData
          const titles = Object.entries(record.recordTitlesData).flatMap(([outerKey, innerObj]) =>
            Object.entries(innerObj).map(([key, titleName]) => ({
              title: `${key}: ${titleName || ''}`, // Include key and value
              recordId: record.recordId,
              title_field_color: record.title_field_color,
              isHeading: false // Indicate this is not a heading
            }))
          );

          // Return the headings followed by the titles
          return [...headings, ...titles];
        }) : [];
      });

    return eventsForDate;
  };





  const findLatestMonthWithEvents = (cardData) => {
    let latestMonth = null;
    let latestYear = null;

    cardData.forEach(card => {
      Object.keys(card).forEach(dateKey => {
        const date = moment(dateKey, 'YYYY-MM-DD HH:mm:ss');
        const month = date.month();
        const year = date.year();

        if (!latestYear || year > latestYear || (year === latestYear && month > latestMonth)) {
          latestMonth = month;
          latestYear = year;
        }
      });
    });

    return { latestMonth, latestYear };
  };


  // Render function for each date cell
  // import { Popover } from 'antd';

  const renderDateCell = (date) => {
    const events = generateEventsForDate(date);
    const isCurrentDate = date.isSame(moment(), 'day'); // Check if date is current date

    // Define styles for the current date
    const currentDateStyle = isCurrentDate ? { borderRadius: '60%', backgroundColor: '#E2E7F8' } : {};

    return (
      <div style={{ background: "#fff", border: 'none', margin: '1.5px', display: 'flex', flexDirection: 'column', height: "20vh" }}>
        <div style={{ padding: '5px', fontFamily: "Inter", fontSize: "12px", fontWeight: 600, display: 'flex', justifyContent: 'space-between' }}>
          <p style={{ padding: "3px 9px", ...currentDateStyle, color: "#192228", opacity: 0.6 }}>{date.format('D')}</p>
          <p style={{ margin: 0, color: "#192228", opacity: 0.6 }}>{date.format('ddd')}</p>
        </div>
        <style>
          {`
        div::-webkit-scrollbar {
          width: 8px;
          height: 2px;
        }
        div::-webkit-scrollbar-track {
          background-color: #f1f1f1;
        }
        div::-webkit-scrollbar-thumb {
          background-color: #C1C1C1;
          border-radius: 4px;
          min-width: 8px;
          max-width: 8px;
          min-height: 8px;
          max-height: 8px;
        }
        div::-webkit-scrollbar-thumb:hover {
          background-color: #7D7D7D;
        }
        `}
        </style>
        <div style={{ flex: 1, overflowY: 'auto' }}>
          {events.map((event, index) => (
            <Popover
              key={index}
              content={
                <div className="popover-content" style={{
                  maxHeight: '100px', // Adjust height as needed
                  maxWidth: '200px',  // Adjust width as needed
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  // padding: '0.5em'
                }}>
                  {events
                    .filter(e => e.recordId === event.recordId && !e.isHeading) // Filter to show only events related to this heading
                    .map((filteredEvent, subIndex) => (
                      <div style={{ fontFamily: "Inter", fontWeight: 400, fontSize: "12px" }} key={subIndex} >
                        {filteredEvent.title}
                      </div>
                    ))}
                </div>
              }
              placement="topLeft"
              trigger="hover"
            >
              <div
                onClick={() => { history.push(`/window/${windowDefinition.ad_window_id}/${event.recordId}`) }}
                key={index}
                style={{
                  marginBottom: "1vh",
                  height: "3vh",
                  color: "#192228",
                  fontFamily: "Inter",
                  fontSize: "12px",
                  fontWeight: 400,
                  padding: "0 0.5em",
                  display: event.isHeading ? 'flex' : 'none',
                  justifyContent: 'space-between',
                  borderLeft: event.isHeading ? '3px solid #276EFA' : '',
                  borderTopLeftRadius: '3px',
                  borderBottomLeftRadius: '3px',
                  cursor: "pointer",
                  fontWeight: event.isHeading ? 'bold' : 'normal' // Apply bold styling to headings
                }}
              >
                <span className='formRecordTitle1' style={{ fontFamily: "Inter", fontSize: "12px" }}>
                  {event.isHeading ? event.title : null}
                </span>
              </div>
            </Popover>
          ))}
        </div>
      </div>
    );
  };






  // Render function for the current view
  const renderView = () => {
    switch (view) {
      case 'day':
        return (
          <div>
            {/* Day view content */}
            <p>Selected Date: {selectedDate.format('YYYY-MM-DD')}</p>
            {renderDateCell(selectedDate)}
          </div>
        );
      case 'week':
        // Generate dates for the week
        const startDate = selectedDate.clone().startOf('week');
        const dates = [...Array(7)].map((_, index) => startDate.clone().add(index, 'days'));
        return (
          <div>
            {/* Week view content */}
            {dates.map((date, index) => (
              <div key={index}>{renderDateCell(date)}</div>
            ))}
          </div>
        );
      case 'month':
        // Generate dates for the month starting from Monday
        const startOfMonth = selectedDate.clone().startOf('month').startOf('week'); // Start from the first Monday of the month
        const endOfMonth = selectedDate.clone().endOf('month').endOf('week');
        const monthDates = [];
        let currentDate = startOfMonth.clone();

        // Loop through each day of the month
        while (currentDate.isSameOrBefore(endOfMonth)) {
          monthDates.push(currentDate.clone());
          currentDate.add(1, 'day');
        }

        // Ensure each row contains exactly seven boxes
        const rows = [];
        for (let i = 0; i < monthDates.length; i += 7) {
          const row = monthDates.slice(i, i + 7);
          // Pad the row with null values if it has fewer than seven dates
          while (row.length < 7) {
            row.push(null);
          }
          rows.push(row);
        }

        return (
          <div>
            {/* Month view content */}
            {rows.map((row, rowIndex) => (
              <div key={rowIndex} style={{ display: 'flex', justifyContent: 'center' }}>
                {row.map((date, index) => (
                  <div key={index} style={{ width: '14%', textAlign: 'center' }}>
                    {date ? renderDateCell(date) : null}
                  </div>
                ))}
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div >
      <Row gutter={16}>
        {/* <Col span={6}>
          <DatePicker onChange={onChange} />
        </Col> */}
        <Col span={2}/>
        <Col span={10} >
          <Button.Group style={{ background: "#2F3856", borderRadius: "20px", fontFamily: "Inter", fontSize: "11px", fontWeight: 500, height: "5vh", padding: "7px" }}>
            <Button
              style={{
                background: view === 'day' ? '#fff' : '#2F3856',
                color: view === 'day' ? '#2F3856' : '#fff',
                borderRadius: view === 'day' ? '20px' : "",
                border: "none",
                height: "3vh",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => handleViewChange('day')}
            >
              Day
            </Button>
            <Button
              style={{
                background: view === 'week' ? '#fff' : '#2F3856',
                color: view === 'week' ? '#2F3856' : '#fff',
                borderRadius: view === 'week' ? '20px' : "",
                border: "none",
                height: "3vh",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => handleViewChange('week')}
            >
              Week
            </Button>
            <Button
              style={{
                background: view === 'month' ? '#fff' : '#2F3856',
                color: view === 'month' ? '#2F3856' : '#fff',
                borderRadius: view === 'month' ? '20px' : "",
                // borderBottomRightRadius: '20px',
                border: "none",
                height: "3vh",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => handleViewChange('month')}
            >
              Month
            </Button>
          </Button.Group>
        </Col>
        <Col span={12}>
          <div style={{ textAlign: 'center', color: '#192228', fontFamily: 'Inter', fontSize: '24px', fontWeight: 600 }}>
            {selectedDate.format('MMMM YYYY')}
          </div>
        </Col>
        
      </Row>
      <div style={{ height: "70vh", overflowY: "scroll", paddingBottom: "0vh" }}>{renderView()}</div>
    </div>
  );
};

export default CalenderView;
